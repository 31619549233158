import * as THREE from 'three';
import { Manifesto as _Manifesto, Dictum } from '../bin/ScreenDirector.js';

class Manifesto extends _Manifesto{
  constructor( scene_directions, workflow ){
    super( scene_directions, workflow );  // Though it doesn't do anything... it is necessary to make 'this' available.

    /* WeThe & Phox.Happening Content */
    this.Phox_Happening = new Dictum( [ workflow.confirm_privileges ],
      {
        on_enter: scene_directions.enter_building,
        on_idle: scene_directions.idle_on_entry,
        on_progress: scene_directions.wtphox_progress,
        on_failure: scene_directions.wtphox_failure,
        on_end: scene_directions.what_the_phox_happening
      }, true );

    /* User Content */
    this.Load_User = new Dictum( [ workflow.load_user, workflow.load_preferences, workflow.coordinate_entrypoint ],
      {
        on_enter: scene_directions.enter_the_loo,
        on_idle: scene_directions.idle_on_loo,
        on_progress: scene_directions.loo_progress,
        on_failure: scene_directions.loo_failure,
        on_end: scene_directions.guardez_leau
      }, true );

    /* Requested Content */
    this.Load_Venue = new Dictum( [ workflow.load_model, workflow.init_controls, workflow.display_model ],
      {
        on_enter: scene_directions.enter_venue,
        on_idle: scene_directions.idle_on_venue_entry,
        on_progress: scene_directions.venue_progress,
        on_failure: scene_directions.venue_failure,
        on_end: scene_directions.venue_loaded
      }, true );
  }
}

export { Manifesto }
