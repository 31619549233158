import React from 'react';
import ReactDOM from 'react-dom/client';
// Screen Director Reference
import { Workflow as _Workflow, SceneAsset3D, CSS3DAsset, SceneTransformation } from '../bin/ScreenDirector.js';
// Support Library Reference
import GUI from 'lil-gui';
import * as THREE from 'three';

// React Component Error Boundary Class
// Refer to --> # https://reactjs.org/docs/error-boundaries.html #
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  // Update state so the next render will show the fallback UI.
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  // You can also log the error to an error reporting service
  componentDidCatch(error, errorInfo) {
    console.error( error, errorInfo );
  }

  render() {
    // You can render any custom fallback UI
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

// Workflow Implementation
class Workflow extends _Workflow{
  elevated_vars = {
    "u_name": "",
    "ups_test": {
      "ticks": 0,
      "duration": 0,
      "stamps": [],
      "max": -1,
      "min": -1,
      "score": 0
    },
    "resume": {
      "objects": [],
      "targets": {
        "timeline": [],
        "table": [],
        "sphere": [],
        "helix": [],
        "grid": []
      }
    },
    "lil_gui": {}
  }
  react_app;

  /* WeThe Content */
  confirm_privileges = async ( screenplay, dictum_name, director, ndx ) => {
    console.log('Workflow.confirm_privileges');

    document.title = 'Workflow.confirm_privileges | Event Model Viewer | MySpace';


    director.emit( `${dictum_name}_progress`, dictum_name, ndx );
  };

  /* Phox.Happening Content */
  load_user = async ( screenplay, dictum_name, director, ndx ) => {
    console.log('Workflow.load_user');

    document.title = 'Workflow.load_user | Event Model Viewer | MySpace';


    director.emit( `${dictum_name}_progress`, dictum_name, ndx );
  }
  load_preferences = async ( screenplay, dictum_name, director, ndx ) => {
    console.log('Workflow.load_preferences');

    document.title = 'Workflow.load_preferences | Event Model Viewer | MySpace';


    director.emit( `${dictum_name}_progress`, dictum_name, ndx );
  }
  coordinate_entrypoint = async ( screenplay, dictum_name, director, ndx ) => {
    console.log('Workflow.coordinate_entrypoint');

    document.title = 'Workflow.coordinate_entrypoint | Event Model Viewer | MySpace';


    director.emit( `${dictum_name}_progress`, dictum_name, ndx );
  }

  /* Local Content */
  load_model = async ( screenplay, dictum_name, director, ndx ) => {
    console.log( 'Workflow.load_model' );
    document.title = 'Workflow.load_model | Event Model Viewer | MySpace';

    try{

      const gui = screenplay.lil_gui;
      gui.open( open );
      const effectController = {
        turbidity: 0,
        rayleigh: 0.755,
        mieCoefficient: 0.002,
        mieDirectionalG: 0.988,
        elevation: 3.7,
        azimuth: 180,
        exposure: screenplay.sys_ve_renderer.toneMappingExposure
      };

      let sky = screenplay.props.sky;
      let sun = new THREE.Vector3();
      function guiChanged() {

        const uniforms = sky.material.uniforms;
        uniforms[ 'turbidity' ].value = effectController.turbidity;
        uniforms[ 'rayleigh' ].value = effectController.rayleigh;
        uniforms[ 'mieCoefficient' ].value = effectController.mieCoefficient;
        uniforms[ 'mieDirectionalG' ].value = effectController.mieDirectionalG;

        const phi = THREE.MathUtils.degToRad( 90 - effectController.elevation );
        const theta = THREE.MathUtils.degToRad( effectController.azimuth );

        sun.setFromSphericalCoords( 1000, phi, theta );
				uniforms[ 'sunPosition' ].value.copy( sun );

        screenplay.props.sun.position.copy( sun );
        screenplay.props.sun.lensflare.position.copy( sun );

        screenplay.sys_ve_renderer.toneMappingExposure = effectController.exposure;
        screenplay.sys_ve_renderer.render( screenplay.sys_ve_scene, screenplay.active_cam );

      }

      gui.add( effectController, 'turbidity', 0.0, 20.0, 0.1 ).onChange( guiChanged );
      gui.add( effectController, 'rayleigh', 0.0, 4, 0.001 ).onChange( guiChanged );
      gui.add( effectController, 'mieCoefficient', 0.0, 0.1, 0.001 ).onChange( guiChanged );
      gui.add( effectController, 'mieDirectionalG', 0.0, 1, 0.001 ).onChange( guiChanged );
      gui.add( effectController, 'elevation', 0, 90, 0.1 ).onChange( guiChanged );
      gui.add( effectController, 'azimuth', - 180, 180, 0.1 ).onChange( guiChanged );
      gui.add( effectController, 'exposure', 0, 1, 0.0001 ).onChange( guiChanged );

      let sys_ve_folder = gui.addFolder( 'Event Model' );
      sys_ve_folder.add ( screenplay.props.Model.scene.position, 'x' ).listen().onChange((value)=>{
        screenplay.props.Model.scene.position.setX( value );
      }).name( 'Model: pos.X' );
      sys_ve_folder.add ( screenplay.props.Model.scene.position, 'y' ).listen().onChange((value)=>{
        screenplay.props.Model.scene.position.setY( value );
      }).name( 'Model: pos.Y' );
      sys_ve_folder.add ( screenplay.props.Model.scene.position, 'z' ).listen().onChange((value)=>{
        screenplay.props.Model.scene.position.setZ( value );
      }).name( 'Model: pos.Z' );
      sys_ve_folder.add ( screenplay.props.Model.scene.rotation, 'x' ).listen().onChange((value)=>{
        screenplay.props.Model.scene.rotateX( value );
      }).name( 'Model: rot.X' );
      sys_ve_folder.add ( screenplay.props.Model.scene.rotation, 'y' ).listen().onChange((value)=>{
        screenplay.props.Model.scene.rotateY( value );
      }).name( 'Model: rot.Y' );
      sys_ve_folder.add ( screenplay.props.Model.scene.rotation, 'z' ).listen().onChange((value)=>{
        screenplay.props.Model.scene.rotateZ( value );
      }).name( 'Model: rot.Z' );

      guiChanged();

      director.emit( `${dictum_name}_progress`, dictum_name, ndx );

    } catch( e ) {

      director.emit( `${dictum_name}_failure`, dictum_name, ndx );

    }
  };
  init_controls = async ( screenplay, dictum_name, director, ndx ) => {
    console.log('Workflow.init_controls');
    document.title = 'Workflow.init_controls | Event Model Viewer | MySpace';

    const gui = screenplay.lil_gui;
    gui.open( true );

    let avatar = screenplay.props.Avatar;
    let avatar_controls_folder = gui.addFolder( 'Avatar Controls' );
    avatar_controls_folder.add ( avatar.position, 'x' ).listen().onChange((value)=>{
      avatar.position.setX( value );
    }).name( 'Avatar Position.x' );
    avatar_controls_folder.add ( avatar.position, 'y' ).listen().onChange((value)=>{
      avatar.position.setY( value );
    }).name( 'Avatar Position.y' );
    avatar_controls_folder.add ( avatar.position, 'z' ).listen().onChange((value)=>{
      avatar.position.setZ( value );
    }).name( 'Avatar Position.z' );


    let model = screenplay.props.Environment.scene;

    //model.children[44].visible = false;

    //let light = model.children[0].children[0];
    let sun = screenplay.props.sun;

    let light_controls_folder = gui.addFolder( 'Light Controls' );
    //light_controls_folder.add( light, 'intensity', 0, 10, 0.1 ).name( 'Light Intensity');
    //light_controls_folder.add( light, 'decay', 0, 2, 1 ).name( 'Light Decay [0,1,2]');
    light_controls_folder.add ( sun.position, 'x' ).listen().onChange((value)=>{
      sun.position.setX( value );
    }).name( 'Sun Position.x' );
    light_controls_folder.add ( sun.position, 'y' ).listen().onChange((value)=>{
      sun.position.setY( value );
    }).name( 'Sun Position.y' );
    light_controls_folder.add ( sun.position, 'z' ).listen().onChange((value)=>{
      sun.position.setZ( value );
    }).name( 'Sun Position.z' );
    light_controls_folder.add ( sun, 'intensity' ).name( 'Sun Intensity' );

    let camera_controls_folder = gui.addFolder( 'Camera Controls' );
    let cameras = screenplay.cameras;
    cameras.forEach( function( cam, name ){
      let ctrl = {};
      ctrl[name] = ()=>{
        screenplay.actions.change_cam( `${name}` )

      };
      camera_controls_folder.add( ctrl, name );
    } );
    camera_controls_folder.add ( screenplay.active_cam.position, 'x' ).listen().onChange((value)=>{
      screenplay.active_cam.position.setX( value );
      screenplay.active_cam.updateProjectionMatrix();
    }).name( 'active_cam: pos.x' );
    camera_controls_folder.add ( screenplay.active_cam.position, 'y' ).listen().onChange((value)=>{
      screenplay.active_cam.position.setY( value );
      screenplay.active_cam.updateProjectionMatrix();
    }).name( 'active_cam: pos.y' );
    camera_controls_folder.add ( screenplay.active_cam.position, 'z' ).listen().onChange((value)=>{
      screenplay.active_cam.position.setZ( value );
      screenplay.active_cam.updateProjectionMatrix();
    }).name( 'active_cam: pos.z' );
    camera_controls_folder.add ( screenplay.active_cam.rotation, 'x' ).listen().onChange((value)=>{
      screenplay.active_cam.rotation.setX( value );
      screenplay.active_cam.updateProjectionMatrix();
    }).name( 'active_cam: rot.x' );
    camera_controls_folder.add ( screenplay.active_cam.rotation, 'y' ).listen().onChange((value)=>{
      screenplay.active_cam.rotation.setY( value );
      screenplay.active_cam.updateProjectionMatrix();
    }).name( 'active_cam: rot.y' );
    camera_controls_folder.add ( screenplay.active_cam.rotation, 'z' ).listen().onChange((value)=>{
      screenplay.active_cam.rotation.setZ( value );
      screenplay.active_cam.updateProjectionMatrix();
    }).name( 'active_cam: rot.z' );
    camera_controls_folder.add( screenplay.active_cam, 'zoom' ).onChange(()=>{
      screenplay.active_cam.updateProjectionMatrix();
    });
    camera_controls_folder.add( screenplay, 'fps' ).name('Frames / Second').onChange(()=>{
      screenplay.interval = 1 / screenplay.fps;
    });
    camera_controls_folder.open( false );

    director.emit( `${dictum_name}_progress`, dictum_name, ndx );
  };
  display_model = async ( screenplay, dictum_name, director, ndx ) => {
    console.log('Workflow.display_model');
    document.title = 'Workflow.display_model | Event Model Viewer | MySpace';

    const gui = screenplay.lil_gui;
    gui.open( true );

    director.emit( `${dictum_name}_progress`, dictum_name, ndx );
  };

  constructor( react_app ){
    super();
    this.react_app = react_app;
  }
}

export { Workflow }
