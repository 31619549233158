import React from 'react';
import ReactDOM from 'react-dom/client';
// ScreenDirector Reference
import { SceneDirections as _SceneDirections, SceneTransformation } from '../bin/ScreenDirector.js';
// Support Library Reference
import GUI from 'lil-gui';
import * as THREE from 'three';
import { Sky } from '../lib/Sky.js';
import { Lensflare, LensflareElement } from '../lib/Lensflare.js';

// React Component Error Boundary Class
// Refer to --> # https://reactjs.org/docs/error-boundaries.html #
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  // Update state so the next render will show the fallback UI.
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  // You can also log the error to an error reporting service
  componentDidCatch(error, errorInfo) {
    console.error( error, errorInfo );
  }

  render() {
    // You can render any custom fallback UI
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

// ScreenDirections Implementation
/*
    This is where the THREE.js code is run.
    New characters need to render to the stage?  Do that here.
    When using lazy Getters for those models, they wont load prematurely.
    Note: Be sure to override the lazy Getter with the initialized model ( See Screenplay.js for an example ).
*/
class SceneDirections extends _SceneDirections {
  react_app;

  /* Phox.Happening Content */
  enter_building = async ( screenplay, dictum_name, next_emit, director )=>{
   console.log('SceneDirections.enter_ready');



   director.emit( next_emit, dictum_name );
  };
  idle_on_entry = async ( screenplay, dictum_name, next_emit, director )=>{
   console.log('SceneDirections.idle_on_ready');



   director.emit( next_emit, dictum_name );
  };
  wtphox_progress = async ( screenplay )=>{
   console.log('SceneDirections.progress_ready');
  };
  wtphox_failure = async ( screenplay )=>{
   console.log('SceneDirections.ready_failure');
  };
  what_the_phox_happening = async ( screenplay, dictum_name, next_emit, director )=>{
   console.log('SceneDirections.ready_for_anything');

   director.emit( next_emit, dictum_name );
  };

  /* Load User Content */
  enter_the_loo = async ( screenplay, dictum_name, next_emit, director )=>{

    console.log('SceneDirections.enter_the_loo');
    const gui = screenplay.lil_gui = new GUI( { title: 'Architect Interface' });


    let sys_ve_scene = screenplay.sys_ve_scene;
    let sys_ui_scene = screenplay.sys_ui_scene;
    let page_ve_scene = screenplay.page_ve_scene;
    let page_ui_scene = screenplay.page_ui_scene;



    let sky = screenplay.props.sky = new Sky();
    sky.scale.setScalar( 450000 );
    sys_ve_scene.add( sky );

    let sun = screenplay.props.sun = new THREE.PointLight(0xffffff, 1);
    sun.position.set( - 3, 10, - 10 );
    sun.castShadow = true;
    sun.shadow.bias = - 0.005; // reduces self-shadowing on double-sided objects
    sun.shadow.camera.top = 2;
    sun.shadow.camera.bottom = - 2;
    sun.shadow.camera.left = - 2;
    sun.shadow.camera.right = 2;
    sun.shadow.camera.near = screenplay.VIEW.near;
    sun.shadow.camera.far = screenplay.VIEW.far;
    sys_ve_scene.add( sun );


    // lensflares
    const textureLoader = new THREE.TextureLoader();

    const textureFlare0 = textureLoader.load( 'textures/lensflare/lensflare0.png' );
    const textureFlare3 = textureLoader.load( 'textures/lensflare/lensflare3.png' );

    const lensflare = new Lensflare();
    lensflare.position.copy( sun.position );
    lensflare.addElement( new LensflareElement( textureFlare0, 700, 0, sun.color ) );
    lensflare.addElement( new LensflareElement( textureFlare3, 60, 0.6 ) );
    lensflare.addElement( new LensflareElement( textureFlare3, 70, 0.7 ) );
    lensflare.addElement( new LensflareElement( textureFlare3, 120, 0.9 ) );
    lensflare.addElement( new LensflareElement( textureFlare3, 70, 1 ) );
    sun.lensflare = lensflare;
    sun.add( lensflare );
    sys_ve_scene.add( sun );


    let avatar = await screenplay.props.Avatar;
    avatar.position.setY( 0.18 );
    avatar.position.setX( 0 );
    avatar.castShadow = true;
    avatar.click = function(){
      this.material.color = new THREE.Color( 0xff00ff );
    }
    screenplay.interactives.push( avatar );
    avatar.updateMatrixWorld( true );
    sys_ve_scene.add( avatar );

    const highlightObject = (objectId) => {
        const object = screenplay.scene.getObjectById(objectId);
        if (object) {
          object.material.emissive.setHex(0xff0000);
          setSelectedObject(object);
        }
      };

    const unhighlightObject = () => {
      if (selectedObject) {
        selectedObject.material.emissive.setHex(0x000000);
        setSelectedObject(null);
      }
    };

    let environment = await screenplay.props.Environment;
    environment.scene.position.setZ( -125 );
    environment.scene.updateMatrixWorld( true );
    environment.buildings = [];
    environment.city = [];
    environment.scene.traverse( function ( child, ndx ){
      if( child.isMesh ){
        if( !child.isPlane ){
          child.click = function(){
            this.highlighted = (this.highlighted) ? false : true;
            if( this.highlighted ){
              this.material.emissive.setHex(0xff0000);
            } else {
              this.material.emissive.setHex(0x000000);
            }
          }
          screenplay.interactives.push( child );
        }
      }
      if( child.isCamera ){
        let name = child.name = child.parent.name || `cam_${ndx}`;
        screenplay.cameras.set( name, child );
      }
    });
    environment.terrain = new THREE.Mesh( new THREE.PlaneGeometry( 1000, 1000 ),new THREE.MeshStandardMaterial( {color: 0x777777} ) );
    environment.terrain.rotateX( -Math.PI / 2 );
    environment.terrain.position.setY( -1 );
    environment.scene.add( environment.terrain );

    sys_ve_scene.add( environment.scene );
    sys_ve_scene.add( screenplay.lights.ambient_light );
    this.enter_splash = new SceneTransformation({
        update: ( delta )=>{
          if( this.enter_splash.cache.frame++ <= this.enter_splash.cache.duration ){
            let prog = this.enter_splash.cache.frame / this.enter_splash.cache.duration;
            environment.buildings.forEach( ( building, ndx )=>{
              building.scale.setZ( prog );
            });
            environment.city.forEach( ( city_part, ndx )=>{
              city_part.scale.setY( prog );
            });
          } else {
            screenplay.updatables.delete( 'enter_splash' );

            this.enter_splash.post( );  // This calls for the cleanup of the object from the scene and the values from itself.
          }
        },
        cache: {
          frame: 1,
          duration: 300
        },
        reset: ()=>{
          this.enter_splash.cache.frame = 1;
          screenplay.updatables.set( 'enter_splash', this.enter_splash );
        },
        post: ( )=>{

          director.emit( next_emit, dictum_name );
        }
      });
    screenplay.updatables.set( 'enter_splash', this.enter_splash );

  };
  idle_on_loo = async ( screenplay, dictum_name, next_emit, director )=>{
    console.log('SceneDirections.idle_on_loo');

    let sys_ve_scene = screenplay.sys_ve_scene;
    let sys_ui_scene = screenplay.sys_ui_scene;
    let page_ve_scene = screenplay.page_ve_scene;
    let page_ui_scene = screenplay.page_ui_scene;

    await screenplay.props.Model;
    let model = screenplay.props.Model;
    model.cameras.forEach( function ( cam, ndx ){
      //cam.quaternion.copy( cam.parent.quaternion );
      let name = cam.name = cam.parent.name || `cam_${ndx}`;
      this.set( name, cam );
    }, screenplay.cameras );
    let fullsize = new THREE.Vector3( 1, 1, 1 );
    model.scene.scale.multiplyScalar( 0 );
    sys_ve_scene.add( model.scene );

    // Ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    sys_ve_scene.add(ambientLight);

    // Directional light
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(50, 50, 50);
    sys_ve_scene.add(directionalLight);



    let event_model_entrance = new SceneTransformation({
      update: ( delta )=>{
        let cache = event_model_entrance.cache;
        if( ++cache.frame <= cache.duration ){
          let progress = cache.frame / cache.duration;
          model.scene.scale.lerp( fullsize, progress );

        } else {
          event_model_entrance.post( );  // This calls for the cleanup of the object from the scene and the values from itself.
        }
      },
      cache: {
        duration: screenplay.slo_mode ? 1 : 30, /* do something in 30 frames */
        frame: 0,
      },
      reset: ()=>{
        entrance_transition.cache.duration = screenplay.slo_mode ? 1 : 30;
        entrance_transition.cache.frame = 0;
        screenplay.updatables.set( 'event_model_entrance', event_model_entrance );
      },
      post: ( )=>{
        screenplay.updatables.delete( 'event_model_entrance' );
        model.scene.scale.copy( fullsize );
        director.emit( next_emit, dictum_name );
      }
    });
    screenplay.updatables.set( 'event_model_entrance', event_model_entrance );
  };
  loo_progress = async ( screenplay )=>{
    console.log('SceneDirections.loo_progress');
  };
  loo_failure = async ( screenplay )=>{
   console.log('SceneDirections.loo_failure');

   // TODO: Here is the place to switch to lo-fps mode for late-model user systems.
  };
  guardez_leau = async ( screenplay, dictum_name, next_emit, director )=>{
     console.log('SceneDirections.guardez_leau');

     // TODO: Confirm to the user that we are now progressing... either in full-fps or lo-fps

     director.emit( next_emit, dictum_name );
    };

  /* Load the Requested Venue Content */
  enter_venue = async ( screenplay, dictum_name, next_emit, director )=>{
    console.log('SceneDirections.enter_venue');
    director.emit( next_emit, dictum_name );
  };
  idle_on_venue_entry = async ( screenplay, dictum_name, next_emit, director )=>{
    console.log('SceneDirections.idle_on_venue_entry');
    director.emit( next_emit, dictum_name );
  };
  venue_progress = async ( screenplay )=>{
    console.log('SceneDirections.venue_progress');
  };
  venue_failure = async ( screenplay )=>{
   console.log('SceneDirections.venue_failure');

   // TODO: Here is the place to switch to lo-fps mode for late-model user systems.
  };
  enter_venue = async ( screenplay, dictum_name, next_emit, director )=>{
    console.log('SceneDirections.enter_venue');

    // TODO: Confirm to the user that we are now progressing... either in full-fps or lo-fps

    director.emit( next_emit, dictum_name );
  };
  venue_loaded = async ( screenplay, dictum_name, next_emit, director )=>{
     console.log('SceneDirections.venue_loaded');

     // TODO: Confirm to the user that we are now progressing... either in full-fps or lo-fps

     director.emit( next_emit, dictum_name );
    };

  constructor( react_app ){
    super();
    this.react_app = react_app;
  }
}

export { SceneDirections }
